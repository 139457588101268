import { Box, Card, CardContent, Divider, Grid, Pagination } from '@mui/material'
import ProviderCard from 'src/views/providerCard'

const serviceList = [
  {
    image: '/assets/images/card.png',
    email: 'johnsmith@gmail.com',
    phone: '9978457966',
    city: 'New-york'
  },
  {
    image: '/assets/images/card.png',
    email: 'johnsmith@gmail.com',
    phone: '9978457966',
    city: 'New-york'
  },
  {
    image: '/assets/images/card.png',
    email: 'johnsmith@gmail.com',
    phone: '9978457966',
    city: 'New-york'
  }
]

const Search = () => {
  return (
    <>
      <Card>
        <CardContent>
          <Grid item xs={12}>
            {serviceList.map(item => {
              return (
                <>
                  <ProviderCard data={item} />
                  <Divider variant='middle' sx={{ m: '24px 0px' }} />
                </>
              )
            })}
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

export default Search