import React, { Suspense } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import FallbackSpinner from './@core/components/spinner'

import AuthGuard from 'src/@core/components/auth/AuthGuard'
import UserLayout from './layouts/UserLayout'
import BlankLayout from './@core/layouts/BlankLayout'
// import BlankLayoutWithAppBar from './@core/layouts/BlankLayoutWithAppBar'
import AclGuard from './@core/components/auth/AclGuard'
import GuestGuard from './@core/components/auth/GuestGuard'
import { defaultACLObj } from './configs/acl'
import AuthLayout from './layouts/AuthLayout'
import Search from './pages/search'

const LoginPage = React.lazy(() => import('./pages/login'))
const RegisterPage = React.lazy(() => import('./pages/register'))
const ForgotPassword = React.lazy(() => import('./pages/forgot-password'))
const VerifyOtp = React.lazy(() => import('./pages/verify-otp'))
const ResetPassword = React.lazy(() => import('./pages/reset-password'))
const HomePage = React.lazy(() => import('./pages/home'))
const PlanDetails = React.lazy(() => import('./pages/home/planDetails'))
const ViewDetails = React.lazy(() => import('./pages/home/viewDetails'))
const ProviderListPage = React.lazy(() => import('./pages/home/providerList'))
const DetailsPage = React.lazy(() => import('./pages/home/providerDetails'))
const ViewDetail = React.lazy(() => import('./pages/providers/view-detail'))
const MakeClaim = React.lazy(() => import('./pages/home/makeClaim'))
const PreventiveExam = React.lazy(() => import('./pages/preventiveExam'))
const ProviderList = React.lazy(() => import('./pages/providers/provider-list/index.js'))
const MyProfile = React.lazy(() => import('./pages/my/profile'))
const ChangePassword = React.lazy(() => import('./pages/my/profile/change-password'))
const MyReimbursements = React.lazy(() => import('./pages/my/reimbursements/index.js'))

const Page401 = React.lazy(() => import('./pages/401'))
const Page404 = React.lazy(() => import('./pages/404'))

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>
  }
}

function App() {
  const aclAbilities = defaultACLObj

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route element={<BlankLayout><Outlet /></BlankLayout>}>
            <Route path='/401' element={<Page401 />} />
            <Route path='/404' element={<Page404 />} />

            <Route element={<AuthLayout><Outlet /></AuthLayout>}>
              <Route element={<Guard guestGuard><Outlet /></Guard>}>
                <Route path='/login' element={<LoginPage />}></Route>
                <Route path='/register' element={<RegisterPage />}></Route>
                <Route path='/forgot-password' element={<ForgotPassword />}></Route>
                <Route path='/verify-otp' element={<VerifyOtp />}></Route>
                <Route path='/reset-password' element={<ResetPassword />}></Route>
              </Route>
            </Route>
          </Route>
          <Route path='/' element={<Navigate to='/myplan' replace />} />

          <Route element={<UserLayout><Outlet /></UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path='/myplan' element={<HomePage />} />
              <Route path='/myplan/plan-details' element={<PlanDetails />} />
              <Route path='/myplan/view-details' element={<ViewDetails />} />
              <Route path='/myplan/make-claim' element={<MakeClaim />} />
              <Route path='/myplan/select-provider/:id' element={<ProviderListPage />} />
              <Route path='/myplan/details/:id' element={<DetailsPage />} />

              <Route path='/exams' element={<PreventiveExam />} />

              <Route path='/providers' element={<ProviderList />} />
              <Route path='/providers/view-detail/:id' element={<ViewDetail />} />
              <Route path='/my/profile' element={<MyProfile />} />
              <Route path='/my/profile/change-password' element={<ChangePassword />} />
              <Route path='/my/reimbursements' element={<MyReimbursements />} />
              <Route path='/search' element={<Search />} />
            </Route>
          </Route>

          {/* If no route found redirect it to --> /404 */}
          <Route path='*' element={<Navigate to='/404' replace />} />

        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
