function PrevantiveExamIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none">
      <path d="M12.3701 8.88H17.6201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.37988 8.88L7.12988 9.63L9.37988 7.38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.3701 15.88H17.6201" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M6.37988 15.88L7.12988 16.63L9.37988 14.38" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default PrevantiveExamIcon