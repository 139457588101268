export const API_BASE_URL = "https://dev.api.famyll.com/member-ms/api/v1"
// export const API_BASE_URL = "https://7906-2405-201-2046-c806-2ef-531a-f20-1322.ngrok-free.app/member-ms/api/v1"

export const ApiEndpoints = {
    AUTH: {
        login: `${API_BASE_URL}/auth/sign-in`,
        forgotPassword: `${API_BASE_URL}/auth/forgot-password`,
        verifyOtp: `${API_BASE_URL}/auth/verify-otp`,
        resendOtp: `${API_BASE_URL}/auth/resend-otp`,
        resetPassword: `${API_BASE_URL}/auth/reset-password`,
        changePassword: `${API_BASE_URL}/auth/change-password`,
        me: `${API_BASE_URL}/auth/me`,
        notifications: `${API_BASE_URL}/auth/notification`
    },
    PROVIDER: {
        providerList: `${API_BASE_URL}/provider/`,
        addProviders: `${API_BASE_URL}/provider/`,
        providerDetails: (id) => `${API_BASE_URL}/provider/${id}`,
        removeProvider: (id) => `${API_BASE_URL}/provider/remove-provider/${id}`
    },
    PREVENTIVE: {
        preventiveList: `${API_BASE_URL}/member/preventive-exam`,
    },
    GET_REGION: {
        country: `https://api.countrystatecity.in/v1/countries`,
        state: `https://api.countrystatecity.in/v1/countries/`,
    },

}