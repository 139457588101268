import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import Translations from 'src/layouts/components/Translations'

const ProviderCard = ({ data, userId, list }) => {
    const navigate = useNavigate();

    return (
        <>
            <Grid container xs={12} justifyContent='space-between' spacing={6}>
                <Grid item xs={12} display="flex">
                    <Box>
                        <Typography variant='fm-h6' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                            <Translations text={data?.provider_type} />
                        </Typography>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{
                    display: 'flex',
                    width: '100%',
                    gap: '16px'
                }}>
                    <Box
                        sx={{
                            background: `url(${data?.profile_image}) lightgray 50% / cover no-repeat`,
                            width: '100px',
                            height: '100px',
                            borderRadius: '20px'
                        }}
                    >

                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography variant='fm-h5' color='neutral.80' fontWeight={600} sx={{ textTransform: 'capitalize' }}>
                            {data?.first_name && data?.last_name && data?.middle_name ? `${data?.first_name} ${data?.last_name} ${data?.middle_name}` : data.business_name}
                        </Typography>

                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                            <Translations text="Email" /> :
                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                <Translations text={data?.email} />
                            </Typography>
                        </Typography>

                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                            <Translations text="Phone" /> :
                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                <Translations text={data?.phone_number} />
                            </Typography>
                        </Typography>

                        <Typography variant='fm-p2' color='#8B7F97' fontWeight={600}>
                            <Translations text="City" /> :
                            <Typography variant='fm-p2' color='neutral.80' fontWeight={600}>
                                <Translations text={data?.city} />
                            </Typography>
                        </Typography>

                        <Box display={{ md: "none" }}>
                            <Link to={`/providers/view-detail/${data?._id}`}>
                                <Button sx={{ backgroundColor: '#EBF3F7', color: '#0B1B22', padding: '8px 16px' }}>
                                    <Typography variant='fm-p3' color='neutral.80' fontWeight={600}>
                                        <Translations text="View Details" />
                                    </Typography>
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Grid>

                <Grid item md={6} display={{ xs: "none", md: "flex" }} justifyContent={{ md: 'flex-end', xs: 'flex-start' }}>
                    <Box>
                        {/* <Link to={`/myplan/details/${data?._id}?member=${userId}`}> */}
                        <Button sx={{ backgroundColor: '#EBF3F7', color: '#0B1B22', padding: '8px 16px' }}
                            onClick={() => {
                                navigate(`/myplan/details/${data?._id}?member=${userId}`, {
                                    state: {
                                        providerSelected: list,
                                        path: 'myplan'
                                    }
                                })
                            }}
                        >
                            <Typography variant='fm-p3' color='neutral.80' fontWeight={600}>
                                <Translations text="View Details" />
                            </Typography>
                        </Button>
                        {/* </Link> */}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

export default ProviderCard