// ** React Imports
import { useState, Fragment, useEffect } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close';

// ** Icons Imports
import BellOutline from 'mdi-material-ui/BellOutline'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Custom Components Imports
import CustomChip from 'src/@core/components/mui/chip'
import CustomAvatar from 'src/@core/components/mui/avatar'
import { Drawer, Grid } from '@mui/material'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import toast from 'react-hot-toast'
import moment from 'moment'

// ** Styled Menu component
const Menu = styled(MuiMenu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    width: 380,
    overflow: 'hidden',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  '& .MuiMenu-list': {
    padding: 0
  }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`
}))

const styles = {
  maxHeight: '90vh',
  '& .MuiMenuItem-root:last-of-type': {
    border: 0
    // padding: "20px 0px 0px 0px"
  }
}

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
  ...styles
})

// ** Styled Avatar component
const Avatar = styled(CustomAvatar)({
  width: '2.375rem',
  height: '2.375rem',
  fontSize: '1.125rem'
})

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  flex: '1 1 100%',
  overflow: 'hidden',
  // fontSize: '0.875rem',
  fontSize: '18px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(0.75),

}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)({
  flex: '1 1 100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 500,
  fontSize: '16px',
  color: '#78649E'
})

const NotificationBox = styled(Box)({
  border: '1px solid #E3E1E5',
  borderRadius: '20px',
  padding: '16px'
})

const NotificationDropdown = props => {
  // ** Props
  const { settings } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)

  // ** Hook
  const hidden = useMediaQuery(theme => theme.breakpoints.down('lg'))

  // ** Vars
  const { direction } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
  }

  const ScrollWrapper = ({ children }) => {
    if (hidden) {
      return <Box sx={{ ...styles, overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    } else {
      return (
        <PerfectScrollbar options={{ wheelPropagation: false, suppressScrollX: true }}>{children}</PerfectScrollbar>
      )
    }
  }

  // const notificationData = [
  //   {
  //     title: 'Your coverage plan is renewed successfully.',
  //     subtitle: '1hr ago',
  //     image: '/assets/images/sucsessCheckIcon.png'
  //   },
  //   {
  //     title: 'Your plan is expired.',
  //     subtitle: '2hr ago',
  //     image: '/assets/images/redCrossIcon.png'
  //   },
  //   {
  //     title: 'Your coverage plan is going to expire.',
  //     subtitle: '2hr ago',
  //     image: '/assets/images/notificationIcon.png'
  //   }
  // ]


  const [loading, setLoading] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])

  const getNotifications = () => {
    setLoading(true);
    axiosInstance
      .get(ApiEndpoints.AUTH.notifications)
      .then((response) => response?.data?.data?.user)
      .then((response) => {
        const notifications = response?.notification || [];
        const sortedNotifications = notifications.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)));
        setNotificationsList(sortedNotifications);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'An error occurred');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getNotifications()
  }, [])

  const getTimeAgo = (date) => {
    return moment(date).fromNow();
  };


  const formatNotificationTitle = (title) => {
    // Replace undefined values with empty strings or meaningful placeholders
    let formattedTitle = title.replace(/undefined/g, '').trim();

    formattedTitle = formattedTitle.replace(/\s+/g, ' ');
    return formattedTitle;
  };

  return (
    <Fragment>
      <IconButton
        sx={{
          bgcolor: 'primary.main',
          color: 'common.white',
          '&:hover': {
            bgcolor: 'primary.dark',  // Change this to the desired darker shade
            color: 'common.white',
          },
        }}
        aria-haspopup="true"
        onClick={handleDropdownOpen}
        aria-controls="customized-menu"
      >
        <BellOutline />
      </IconButton>
      <Drawer
        anchor='right'
        open={anchorEl}
        variant='temporary'
        onClose={handleDropdownClose}
        PaperProps={{
          sx: {
            width: '35%',
            borderTopLeftRadius: '35px',
            borderBottomLeftRadius: '35px'
          }
        }}
      >

        <Grid container>

          <Grid item xs={12} m={8}>
            {/* <IconButton sx={{ top: '10px', right: '10px' }}>
              <img src='/assets/images/notification/close-circle.png' alt='closeImage' ></img>
            </IconButton> */}
            <Typography variant='fm-h5' color='neutral.80' fontWeight={600}>
              Notifications
            </Typography>

            <ScrollWrapper>
              {notificationsList?.map((notification, index) => (
                <MenuItem sx={{ border: '0px', padding: '20px 0px 0px 0px' }} onClick={handleDropdownClose} key={index}>
                  <NotificationBox sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    {(notification.notification_subject === "renewal" || notification.notification_subject === "add_provider") ? (
                      <img alt='Notification' src='/assets/images/sucsessCheckIcon.png' />
                    ) : (notification.notification_subject === "expired" || notification.notification_subject === "cancelled") ? (
                      <img alt='Notification' src='/assets/images/redCrossIcon.png' />
                    ) : (
                      <img alt='Notification' src='/assets/images/notificationIcon.png' />
                    )}
                    <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                      <MenuItemTitle>
                        <Typography variant="fm-p1" fontWeight={400} color="neutral.70">{formatNotificationTitle(notification.notification_title)}</Typography>
                      </MenuItemTitle>
                      <MenuItemSubtitle>
                        <Typography variant="fm-p2" fontWeight={500} color="#78649E">{getTimeAgo(notification.createdAt)}</Typography>
                      </MenuItemSubtitle>
                      <Box mt={3}>
                        {notification.notification_subject === "expire" &&
                          <Button variant="contained">Renew Now</Button>
                        }
                        {notification.notification_subject === "cancelled" &&
                          <Button variant="contained">Check Now</Button>
                        }
                        {notification.notification_subject === "claim" &&
                          <Button variant="contained">Claim Now</Button>
                        }
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <img alt='Notification End Icon' src='/assets/images/Active.png' />
                    </Box>
                  </NotificationBox>
                </MenuItem>
              ))}
            </ScrollWrapper>
          </Grid>
        </Grid>
      </Drawer>
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <MenuItem disableRipple>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography sx={{ fontWeight: 600 }}>Notifications</Typography>
            <CustomChip
              skin='light'
              size='small'
              label='8 New'
              color='primary'
              sx={{ height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px' }}
            />
          </Box>
        </MenuItem>
        <ScrollWrapper>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='Flora' src='/images/avatars/4.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Congratulation Flora! 🎉</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>Won the monthly best seller badge</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                Today
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar skin='light'>VU</Avatar>
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>New user registered.</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>5 hours ago</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                Yesterday
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='message' src='/images/avatars/5.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>New message received 👋🏻</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>You have 10 unread messages</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                11 Aug
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img width={38} height={38} alt='paypal' src='/images/misc/paypal.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Paypal</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>Received Payment</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                25 May
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Avatar alt='order' src='/images/avatars/3.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Revised Order 📦</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>New order revised from john</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                19 Mar
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={handleDropdownClose}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <img width={38} height={38} alt='chart' src='/images/misc/chart.png' />
              <Box sx={{ mx: 4, flex: '1 1', display: 'flex', overflow: 'hidden', flexDirection: 'column' }}>
                <MenuItemTitle>Finance report has been generated</MenuItemTitle>
                <MenuItemSubtitle variant='body2'>25 hrs ago</MenuItemSubtitle>
              </Box>
              <Typography variant='caption' sx={{ color: 'text.disabled' }}>
                27 Dec
              </Typography>
            </Box>
          </MenuItem>
        </ScrollWrapper>
        <MenuItem
          disableRipple
          sx={{ py: 3.5, borderBottom: 0, borderTop: theme => `1px solid ${theme.palette.divider}` }}
        >
          <Button fullWidth variant='contained' onClick={handleDropdownClose}>
            Read All Notifications
          </Button>
        </MenuItem>
      </Menu> */}
    </Fragment>
  )
}

export default NotificationDropdown