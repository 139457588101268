// ** Icon imports
import PortfolioIcon from 'src/@core/components/icons/PortfolioIcon'
import ProfileIcon from 'src/@core/components/icons/ProfileIcon'
import ProviderIcon from 'src/@core/components/icons/ProviderIcon'
import PrevantiveExamIcon from 'src/@core/components/icons/PrevantiveExamIcon'
import MyReimbursements from 'src/@core/components/icons/MyReimbursements'

const navigation = () => {
  return [
    {
      title: 'My Plan',
      icon: PortfolioIcon,
      path: '/myplan'
    },
    {
      title: 'Preventive Exams',
      icon: PrevantiveExamIcon,
      path: '/exams'
    },
    {
      title: 'Providers',
      icon: ProviderIcon,
      path: '/providers'
    },
    {
      title: 'My Profile',
      icon: ProfileIcon,
      path: '/my/profile'
    },
    {
      title: 'My Reimbursements',
      icon: MyReimbursements,
      path: '/my/reimbursements'
    },
  ]
}

export default navigation
