// ** MUI Imports
import { styled, useTheme } from '@mui/material/styles'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import MuiAppBar from '@mui/material/AppBar'
import MuiToolbar from '@mui/material/Toolbar'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
// ** Util Import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'
import { Box, Container, IconButton, InputAdornment, TextField, Typography } from '@mui/material'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import NotificationDropdown from '../../shared-components/NotificationDropdown'
import Translations from 'src/layouts/components/Translations'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  transition: 'none',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 6),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: theme.mixins.toolbar.minHeight,
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}))

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  width: '100%',
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  padding: `${theme.spacing(0)} !important`,
  minHeight: `${theme.mixins.toolbar.minHeight}px !important`,
  transition: 'padding .25s ease-in-out, box-shadow .25s ease-in-out, backdrop-filter .25s ease-in-out'
}))

const LayoutAppBar = props => {
  // ** Props
  const { settings, verticalAppBarContent: userVerticalAppBarContent, hidden, toggleNavVisibility } = props

  // ** Hooks
  const theme = useTheme()
  const scrollTrigger = useScrollTrigger({ threshold: 0, disableHysteresis: true })
  const location = useLocation()
  const navigate = useNavigate()

  // ** Vars
  const { skin, appBar, appBarBlur, contentWidth } = settings

  const appBarFixedStyles = () => {
    return {
      px: `${theme.spacing(5)} !important`,
      ...(appBarBlur && { backdropFilter: 'blur(8px)' }),
      boxShadow: theme.shadows[skin === 'bordered' ? 0 : 3],
      backgroundColor: hexToRGBA(theme.palette.background.paper, appBarBlur ? 0.85 : 1),
      ...(skin === 'bordered' && { border: `1px solid ${theme.palette.divider}`, borderTopWidth: 0 })
    }
  }
  if (appBar === 'hidden') {
    return null
  }
  const goBack = () => {
    window.history.back()
  }
  const openSearchPage = () => {
    if (location.pathname !== '/search') {
      navigate('/search');
    }
  };
  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: "26px", paddingRight: "26px", paddingTop: "10px" }}>
        <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
            {(location.pathname !== '/myplan' && location.pathname !== '/exams' && location.pathname !== '/providers' && location.pathname !== '/my/profile' && location.pathname !== '/my/reimbursements' && location.pathname !== '/search') && (
              <Box>
                <IconButton sx={{ backgroundColor: '#7338AC' }} onClick={goBack}>
                  <ArrowBackIosNewRoundedIcon fontSize='small' sx={{ color: '#ffffff' }} />
                </IconButton>
              </Box>
            )}
            <Box>
              {location.pathname === '/myplan' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="My Plan" />
                </Typography>
              )}
              {location.pathname === '/myplan/view-details' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="General Practitioner" />
                </Typography>
              )}
              {location.pathname === '/myplan/plan-details' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="My Plan Details" />
                </Typography>
              )}
              {location.pathname === '/myplan/make-claim' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Make A Claim" />
                </Typography>
              )}
              {location.pathname === '/exams' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Preventive Exam" />
                </Typography>
              )}
              {location.pathname === '/providers' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Provider" />
                </Typography>
              )}
              {location.pathname.includes('/providers/view-detail') && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Provider Detail" />
                </Typography>
              )}
              {location.pathname === '/my/profile/change-password' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Change Password" />
                </Typography>
              )}

              {location.pathname === '/my/profile' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="My Profile" />
                </Typography>
              )}
              {location.pathname === '/my/reimbursements' && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="My Reimbursements" />
                </Typography>
              )}
              {location.pathname.includes('/myplan/select-provider/') && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Select Provider" />
                </Typography>
              )}
              {location.pathname.includes('/myplan/details/') && (
                <Typography variant='fm-h5' fontWeight={600} color='neutral.80'>
                  <Translations text="Provider Detail" />
                </Typography>
              )}

            </Box>
          </Box>
        </Box>
        <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
          {hidden ? (
            <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={toggleNavVisibility}>
              <MenuIcon />
            </IconButton>
          ) : null}
          {/* <Autocomplete hidden={hidden} settings={settings} /> */}
        </Box>
        <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center', gap: 2, width: location.pathname === '/search' ? '100%' : 'auto', justifyContent: location.pathname !== '/search' ? 'flex-end' : 'auto' }}>
          {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
          {/* <ModeToggler settings={settings} saveSettings={saveSettings} /> */}

          <Box sx={{
            width: location.pathname === '/search' ? '100%' : 'auto'
          }}>
            <TextField
              type='search'
              size='small'
              fullWidth={location.pathname === '/search'}
              // value={search}
              placeholder='Search here'
              // onChange={e => setSearch(e.target.value)}
              // onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton>
                      <img
                        src='/assets/images/search-normal.png'
                        alt='Search'
                        style={{ width: '24px', height: '24px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onClick={openSearchPage}
            />
          </Box>
          <NotificationDropdown settings={settings} />
          {/* <UserDropdown settings={settings} /> */}
        </Box>
      </Box>
    </>
    // <AppBar elevation={0} color='default' className='layout-navbar' position={appBar === 'fixed' ? 'sticky' : 'static'}>
    //   <Toolbar
    //     className='navbar-content-container'
    //     sx={{
    //       ...(appBar === 'fixed' && scrollTrigger && { ...appBarFixedStyles() }),
    //       ...(contentWidth === 'boxed' && {
    //         '@media (min-width:1440px)': { maxWidth: `calc(1440px - ${theme.spacing(6)} * 2)` }
    //       })
    //     }}
    //   >
    //     {(userVerticalAppBarContent && userVerticalAppBarContent(props)) || null}
    //   </Toolbar>
    // </AppBar>
  )
}

export default LayoutAppBar
